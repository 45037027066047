import { Outlet } from 'react-router-dom'

import { Admin, Auth } from 'Components/Layout'
import { Lazy } from 'Components/Loader'

import Password from 'Components/FormGroup/Password'

const admin = [
    {
        path: 'admin',
        element: <Auth guard="admin" />,
        children: [
            { path: 'verify-email', element: <Lazy view={'Auth/VerifyEmail'} /> },
            { path: 'verify-email/:id/:hash', element: <Lazy view={'Auth/VerifyEmail'} /> },
        ],
    },
    {
        path: 'admin',
        element: <Admin guard="admin" />,
        children: [
            { path: '', element: <Lazy view={'Admin/Dashboard'} /> },
            { path: 'profile', element: <Lazy view={'Admin/Dashboard/Profile'} /> },
            { path: 'password', element: <Password /> },
            { path: 'send-mail', element: <Lazy view={'Admin/Dashboard/SendMail'} title="Send Mail" /> },
            {
                path: 'admins',
                element: <Outlet />,
                children: [
                    { path: '', element: <Lazy view={'Admin/Admins/List'} /> },
                    { path: 'create', element: <Lazy view={'Admin/Admins/Create'} title="Add new" /> },
                    { path: ':id/edit', element: <Lazy view={'Admin/Admins/Create'} title="Edit" /> },
                ],
            },
            {
                path: 'collection-points',
                element: <Outlet />,
                children: [
                    { path: '', element: <Lazy view={'Admin/CollectionPoints/List'} /> },
                    { path: 'create', element: <Lazy view={'Admin/CollectionPoints/Create'} title="Add new Collection Point" /> },
                    { path: ':id/edit', element: <Lazy view={'Admin/CollectionPoints/Create'} title="Edit" /> },
                ],
            },
            {
                path: 'collection-point-bag-requests',
                element: <Outlet />,
                children: [
                    { path: '', element: <Lazy view={'Admin/CollectionPointBagRequests/List'} /> },
                    {
                        path: 'create',
                        element: (
                            <Lazy
                                view={'Admin/CollectionPointBagRequests/Create'}
                                title="Request Bags or Bins for a Collection Point"
                            />
                        ),
                    },
                    { path: ':id/edit', element: <Lazy view={'Admin/CollectionPointBagRequests/Create'} title="Edit" /> },
                ],
            },
            {
                path: 'affiliates',
                element: <Outlet />,
                children: [
                    { path: '', element: <Lazy view={'Admin/Affiliates/List'} /> },
                    { path: 'create', element: <Lazy view={'Admin/Affiliates/Create'} title="Add new Recycler" /> },
                    { path: ':id/edit', element: <Lazy view={'Admin/Affiliates/Create'} title="Edit" /> },
                ],
            },
            {
                path: 'users',
                element: <Outlet />,
                children: [
                    { path: '', element: <Lazy view={'Admin/Users/List'} /> },
                    { path: 'create', element: <Lazy view={'Admin/Users/Create'} title="Add new" /> },
                    { path: ':id/edit', element: <Lazy view={'Admin/Users/Create'} title="Edit" /> },
                ],
            },
            {
                path: 'collectors',
                element: <Outlet />,
                children: [
                    { path: '', element: <Lazy view={'Admin/Collectors/List'} /> },
                    { path: 'create', element: <Lazy view={'Admin/Collectors/Create'} title="Add new Collector" /> },
                    { path: ':id/edit', element: <Lazy view={'Admin/Collectors/Create'} title="Edit" /> },
                ],
            },
            {
                path: 'collector-bag-requests',
                element: <Outlet />,
                children: [
                    { path: '', element: <Lazy view={'Admin/CollectorBagRequests/List'} /> },
                    {
                        path: 'create',
                        element: <Lazy view={'Admin/CollectorBagRequests/Create'} title="Bags Requested by Collector" />,
                    },
                    { path: ':id/edit', element: <Lazy view={'Admin/CollectorBagRequests/Create'} title="Edit" /> },
                ],
            },
            {
                path: 'projects',
                element: <Outlet />,
                children: [
                    { path: '', element: <Lazy view={'Admin/Projects/List'} /> },
                    { path: 'create', element: <Lazy view={'Admin/Projects/Create'} title="Add new Project" /> },
                    { path: ':id/edit', element: <Lazy view={'Admin/Projects/Create'} title="Edit" /> },
                ],
            },
            {
                path: 'measurements',
                element: <Outlet />,
                children: [
                    { path: '', element: <Lazy view={'Admin/Measurements/List'} /> },
                    { path: 'create', element: <Lazy view={'Admin/Measurements/Create'} title="Add new" /> },
                    { path: ':id/edit', element: <Lazy view={'Admin/Measurements/Create'} title="Edit" /> },
                ],
            },
            {
                path: 'materials',
                element: <Outlet />,
                children: [
                    { path: '', element: <Lazy view={'Admin/Materials/List'} /> },
                    { path: 'create', element: <Lazy view={'Admin/Materials/Create'} title="Add new" /> },
                    { path: ':id/edit', element: <Lazy view={'Admin/Materials/Create'} title="Edit" /> },
                ],
            },
            {
                path: 'batch-reject-types',
                element: <Outlet />,
                children: [
                    { path: '', element: <Lazy view={'Admin/BatchRejectTypes/List'} /> },
                    { path: 'create', element: <Lazy view={'Admin/BatchRejectTypes/Create'} title="Add new" /> },
                    { path: ':id/edit', element: <Lazy view={'Admin/BatchRejectTypes/Create'} title="Edit" /> },
                ],
            },
            {
                path: 'waste-drops',
                element: <Outlet />,
                children: [
                    { path: '', element: <Lazy view={'Admin/WasteDrops/List'} /> },
                    { path: 'create', element: <Lazy view={'Admin/WasteDrops/Create'} title="Waste Bag Drops " /> },
                    { path: ':id/edit', element: <Lazy view={'Admin/WasteDrops/Create'} title="Edit" /> },
                    { path: ':id', element: <Lazy view={'Admin/WasteDrops/Show'} title="View" /> },
                ],
            },
            {
                path: 'cages',
                element: <Outlet />,
                children: [
                    { path: '', element: <Lazy view={'Admin/Cages/List'} /> },
                    { path: 'create', element: <Lazy view={'Admin/Cages/Create'} title="Add new Box" /> },
                    { path: ':id/edit', element: <Lazy view={'Admin/Cages/Create'} title="Edit" /> },
                    { path: ':id', element: <Lazy view={'Admin/Cages/Show'} title="View" /> },
                ],
            },
            {
                path: 'bulka-bags',
                element: <Outlet />,
                children: [
                    { path: '', element: <Lazy view={'Admin/BulkoBags/List'} /> },
                    {
                        path: 'create',
                        element: <Lazy view={'Admin/BulkoBags/Create'} title="Generate QR tag for Bulka Bag Bin" />,
                    },
                    { path: ':id/edit', element: <Lazy view={'Admin/BulkoBags/Create'} title="Edit" /> },
                    { path: ':id', element: <Lazy view={'Admin/BulkoBags/Show'} title="View" /> },
                ],
            },
            {
                path: 'pickups',
                element: <Outlet />,
                children: [
                    { path: '', element: <Lazy view={'Admin/Pickups/List'} /> },
                    { path: 'create', element: <Lazy view={'Admin/Pickups/Create'} title="Book shipment" /> },
                    { path: ':id', element: <Lazy view={'Admin/Pickups/Show'} title="View" /> },
                ],
            },
            {
                path: 'batches',
                element: <Outlet />,
                children: [
                    { path: '', element: <Lazy view={'Admin/Batches/List'} /> },
                    { path: 'create', element: <Lazy view={'Admin/Batches/Create'} title="Add new Batch" /> },
                    { path: ':id', element: <Lazy view={'Admin/Batches/Show'} title="View" /> },
                    // { path: ':id/edit', element: <Lazy view={'Admin/Batches/Create'} title="Edit" /> },
                ],
            },
            {
                path: 'bag-types',
                element: <Outlet />,
                children: [
                    { path: '', element: <Lazy view={'Admin/BagTypes/List'} /> },
                    { path: 'create', element: <Lazy view={'Admin/BagTypes/Create'} title="Add new" /> },
                    { path: ':id/edit', element: <Lazy view={'Admin/BagTypes/Create'} title="Edit" /> },
                ],
            },
            {
                path: 'file-uploads',
                element: <Outlet />,
                children: [
                    { path: '', element: <Lazy view={'Admin/FileUploads/List'} /> },
                    { path: 'create', element: <Lazy view={'Admin/FileUploads/Create'} title="Resources" /> },
                    { path: ':id/edit', element: <Lazy view={'Admin/FileUploads/Create'} title="Edit" /> },
                ],
            },
            {
                path: 'contacts',
                element: <Outlet />,
                children: [
                    { path: '', element: <Lazy view={'Admin/Contacts/List'} /> },
                    { path: ':id', element: <Lazy view={'Admin/Contacts/Show'} title="View" /> },
                ],
            },
            {
                path: 'member-products',
                element: <Outlet />,
                children: [
                    { path: '', element: <Lazy view={'Admin/MemberProducts/List'} /> },
                    { path: 'create', element: <Lazy view={'Admin/MemberProducts/Create'} title="Add new" /> },
                    { path: ':id/edit', element: <Lazy view={'Admin/MemberProducts/Create'} title="Edit" /> },
                ],
            },
            {
                path: 'report',
                element: <Outlet />,
                children: [
                    {
                        path: 'batch-detailed',
                        element: <Lazy view={'Admin/Report/BatchDetailed/List'} title="Batch Detailed Report" />,
                    },
                    { path: 'batch-detailed/:id', element: <Lazy view={'Admin/Report/BatchDetailed/Show'} title="View" /> },
                    {
                        path: 'collector-drop-off',
                        element: <Lazy view={'Admin/Report/CollectorDropOff/List'} title="Collector Drop-off Report" />,
                    },
                    {
                        path: 'collector-drop-off/:id',
                        element: <Lazy view={'Admin/Report/CollectorDropOff/Show'} title="View" />,
                    },
                    {
                        path: 'collector-bag-distribution',
                        element: (
                            <Lazy view={'Admin/Report/CollectorBagDistribution'} title="Collector Bag Distribution Report" />
                        ),
                    },
                    {
                        path: 'collection-point-bag-distribution',
                        element: (
                            <Lazy
                                view={'Admin/Report/CollectionPointBagDistribution'}
                                title="Collection Point Bag Distribution Report"
                            />
                        ),
                    },

                    { path: 'project', element: <Lazy view={'Admin/Report/Project/List'} title="Project Report" /> },
                    { path: 'project/:id', element: <Lazy view={'Admin/Report/Project/Show'} title="View" /> },
                    { path: 'monthly', element: <Lazy view={'Admin/Report/Monthly'} title="Monthly Report" /> },
                ],
            },
        ],
    },
]

export default admin
