import { LuLayoutDashboard } from 'react-icons/lu'
import { FaTruck, FaUsersCog, FaUserSecret, FaUserTie } from 'react-icons/fa'
import { HiOutlineBuildingStorefront } from 'react-icons/hi2'
import { GiGreenhouse } from 'react-icons/gi'
import { AiFillProject } from 'react-icons/ai'
import { FaTruckFast } from 'react-icons/fa6'
import { GrContact, GrDriveCage } from 'react-icons/gr'
import { BiSolidShoppingBags } from 'react-icons/bi'
import { VscGroupByRefType, VscServerProcess } from 'react-icons/vsc'
import { MdOutlineFileUpload, MdOutlineMailOutline } from 'react-icons/md'
import { IoCloudUploadOutline } from 'react-icons/io5'
import { TbReport } from 'react-icons/tb'

const appRoutes = [
    {
        text: 'Menu',
        child: [
            {
                url: '/admin',
                text: 'Dashboard',
                icon: <LuLayoutDashboard />,
            },
            {
                active: ['/admin/admins'],
                url: '/admin/admins',
                text: 'Admins',
                icon: <FaUserSecret />,
            },
            {
                active: ['/admin/collection-points', '/admin/collection-point-bag-requests'],
                url: '/admin/collection-points',
                text: 'Collection Points',
                icon: <HiOutlineBuildingStorefront />,
                child: [
                    {
                        url: '/admin/collection-points',
                        text: 'Collection Points',
                    },
                    {
                        active: ['/admin/collection-point-bag-requests'],
                        url: '/admin/collection-point-bag-requests',
                        text: 'Request Bags',
                    },
                ],
            },

            {
                active: ['/admin/member-products'],
                url: '/admin/member-products',
                text: 'Member Products',
                icon: <IoCloudUploadOutline />,
            },
            {
                active: ['/admin/collectors', '/admin/collector-bag-requests'],
                url: '/admin/collectors',
                text: 'Collectors',
                icon: <FaUserTie />,
                child: [
                    {
                        url: '/admin/collectors',
                        text: 'Collectors',
                    },
                    {
                        active: ['/admin/collector-bag-requests'],
                        url: '/admin/collector-bag-requests',
                        text: 'Request Bags',
                    },
                ],
            },
            {
                active: ['/admin/projects'],
                url: '/admin/projects',
                text: 'Projects',
                icon: <AiFillProject />,
            },
            {
                active: ['/admin/affiliates'],
                url: '/admin/affiliates',
                text: 'Recyclers',
                icon: <GiGreenhouse />,
            },
            {
                active: ['/admin/users'],
                url: '/admin/users',
                text: 'Users',
                icon: <FaUsersCog />,
                child: [
                    {
                        url: '/admin/users',
                        text: 'Users',
                    },
                    {
                        url: '/admin/users/create',
                        text: 'Add new',
                    },
                ],
            },

            {
                active: ['/admin/waste-drops'],
                url: '/admin/waste-drops',
                text: 'Waste Bag Drops',
                icon: <FaTruckFast />,
            },
            {
                active: ['/admin/cages'],
                url: '/admin/cages',
                text: 'ResiLoop Box',
                icon: <GrDriveCage />,
            },
            {
                active: ['/admin/bulka-bags'],
                url: '/admin/bulka-bags',
                text: 'Bulka Bag Bin',
                icon: <BiSolidShoppingBags />,
            },
            {
                active: ['/admin/pickups'],
                url: '/admin/pickups',
                text: 'Shipments',
                icon: <FaTruck />,
            },
            {
                active: ['/admin/batches'],
                url: '/admin/batches',
                text: 'Batches',
                icon: <VscServerProcess />,
            },
            {
                active: ['/admin/measurements', '/admin/materials', '/admin/batch-reject-types', '/admin/bag-types'],
                text: 'Settings',
                icon: <VscGroupByRefType />,
                child: [
                    {
                        active: ['/admin/measurements'],
                        url: '/admin/measurements',
                        text: 'Measurements',
                    },
                    {
                        active: ['/admin/materials'],
                        url: '/admin/materials',
                        text: 'Materials',
                    },
                    {
                        active: ['/admin/batch-reject-types'],
                        url: '/admin/batch-reject-types',
                        text: 'Batch Reject Categories',
                    },
                    {
                        active: ['/admin/bag-types'],
                        url: '/admin/bag-types',
                        text: 'Bag type',
                    },
                ],
            },
            {
                active: ['/admin/send-mail'],
                url: '/admin/send-mail',
                text: 'Send Mail',
                icon: <MdOutlineMailOutline />,
            },
            {
                active: ['/admin/file-uploads'],
                url: '/admin/file-uploads',
                text: 'Resources',
                icon: <MdOutlineFileUpload />,
            },
            {
                active: ['/admin/contacts'],
                url: '/admin/contacts',
                text: 'Messages',
                icon: <GrContact />,
            },

            {
                active: ['/admin/report'],
                text: 'Report',
                icon: <TbReport />,
                child: [
                    {
                        active: ['/admin/report/batch-detailed'],
                        url: '/admin/report/batch-detailed',
                        text: 'Batch Detailed',
                    },
                    {
                        active: ['/admin/report/collector-drop-off'],
                        url: '/admin/report/collector-drop-off',
                        text: 'Collector Drop-off',
                    },
                    {
                        active: ['/admin/report/collector-bag-distribution'],
                        url: '/admin/report/collector-bag-distribution',
                        text: 'Collector Bag Distribution',
                    },
                    {
                        active: ['/admin/report/collection-point-bag-distribution'],
                        url: '/admin/report/collection-point-bag-distribution',
                        text: 'Collection Point Bag Distribution',
                    },
                    {
                        active: ['/admin/report/project'],
                        url: '/admin/report/project',
                        text: 'Project',
                    },
                    {
                        active: ['/admin/report/monthly'],
                        url: '/admin/report/monthly',
                        text: 'Monthly',
                    },
                ],
            },
        ],
    },
]

export default appRoutes
