export function userName(user) {
    return (
        <div className="flex items-center gap-3">
            <div className="relative w-full h-11 max-w-11">
                <img src={user.image_url + '?h=44&w=44&aspect=true&type=webp'} alt={user.name} />
            </div>
            <div className="w-full truncate">
                <p className="text-black dark:text-white">{user.name}</p>
                <p className="text-xs font-medium">{user.email}</p>
            </div>
        </div>
    )
}

export function storeName(store) {
    return (
        <div className="flex items-center gap-3">
            <div className="relative w-full h-11 max-w-11">
                <img src={store.logo_url + '?h=44&w=44&aspect=true&type=webp'} alt={store.business_name} />
            </div>
            <div className="w-full truncate">
                <p className="text-black dark:text-white">{store.business_name}</p>
                <p className="text-xs font-medium">{store.number}</p>
            </div>
        </div>
    )
}

export function userVerified(verified, check = false) {
    var className = ''
    var name = ''
    switch (verified) {
        case null:
            className = 'text-danger bg-danger'
            name = 'Not Verified'
            break
        default:
            className = 'text-success bg-success'
            name = 'Verified'
            break
    }
    if (check) return name
    return <div className={`inline-flex rounded-full bg-opacity-10 py-1 px-3 text-sm font-medium ${className}`}>{name}</div>
}

export function userStatus(status, check = false) {
    var className = ''
    var name = ''
    switch (status) {
        case 1:
            className = 'text-success bg-success'
            name = 'Active'
            break
        default:
            className = 'text-danger bg-danger'
            name = 'Inactive'
            break
    }
    if (check) return name
    return <div className={`inline-flex rounded-full bg-opacity-10 py-1 px-3 text-sm font-medium ${className}`}>{name}</div>
}
export function CageStatus(status, check = false) {
    var className = ''
    var name = ''
    switch (status) {
        case 1:
            className = 'text-success bg-success'
            name = 'On Site'
            break
        default:
            className = 'text-danger bg-danger'
            name = 'Inactive'
            break
    }
    if (check) return name
    return <div className={`inline-flex rounded-full bg-opacity-10 py-1 px-3 text-sm font-medium ${className}`}>{name}</div>
}

export function BagTypes(types = '', check = false) {
    var className = ''
    var name = ''
    switch (types) {
        case 0:
            className = 'text-warning bg-warning'
            name = 'Bulka Bags'
            break
        case 1:
            className = 'text-info bg-info'
            name = 'Collection Bags'
            break
        default:
            name = [
                {
                    id: 0,
                    label: 'Bulka Bags',
                },
                {
                    id: 1,
                    label: 'Collection Bags',
                },
            ]
            break
    }
    if (check || !className) return name
    return <div className={`inline-flex rounded-full bg-opacity-10 py-1 px-3 text-sm font-medium ${className}`}>{name}</div>
}
export function collectionPointsType(type = '', check = false) {
    var className = ''
    var name = ''
    switch (type) {
        case 0:
            className = 'text-warning bg-warning'
            name = 'Commerical contractor'
            break
        case 1:
            className = 'text-info bg-info'
            name = 'Retail store'
            break
        case 2:
            className = 'text-danger bg-danger'
            name = 'Accessory suppliers'
            break
        case 3:
            className = 'text-danger bg-danger'
            name = 'Other'
            break
        default:
            name = [
                {
                    id: 0,
                    label: 'Commerical contractor',
                },
                {
                    id: 1,
                    label: 'Retail store',
                },
                {
                    id: 2,
                    label: 'Accessory suppliers',
                },
                {
                    id: 3,
                    label: 'Other',
                },
            ]
            break
    }
    if (check || !className) return name
    return <div className={`inline-flex rounded-full bg-opacity-10 py-1 px-3 text-sm font-medium ${className}`}>{name}</div>
}
export function AffiliateType(types = '', check = false) {
    var className = ''
    var name = ''
    switch (types) {
        case 0:
            className = 'text-warning bg-warning'
            name = 'Reprocessor'
            break
        case 1:
            className = 'text-info bg-info'
            name = 'Manufacturer'
            break
        case 2:
            className = 'text-danger bg-danger'
            name = 'Other'
            break
        default:
            name = [
                {
                    id: 0,
                    label: 'Reprocessor',
                },
                {
                    id: 1,
                    label: 'Manufacturer',
                },
                {
                    id: 2,
                    label: 'Other',
                },
            ]
            break
    }
    if (check || !className) return name
    return <div className={`inline-flex rounded-full bg-opacity-10 py-1 px-3 text-sm font-medium ${className}`}>{name}</div>
}
export function userStore(store = '', check = false) {
    var className = ''
    var name = ''
    switch (store) {
        case 0:
            className = 'text-warning bg-warning'
            name = 'Collection Point'
            break
        case 1:
            className = 'text-info bg-info'
            name = 'Recycler'
            break
        default:
            name = [
                {
                    id: 0,
                    label: 'Collection Point',
                },
                {
                    id: 1,
                    label: 'Recycler',
                },
            ]
            break
    }
    if (check || !className) return name
    return <div className={`inline-flex rounded-full bg-opacity-10 py-1 px-3 text-sm font-medium ${className}`}>{name}</div>
}

export function userRole(role_id = '', check = false) {
    var className = ''
    var name = ''
    switch (role_id) {
        case 0:
            className = 'text-warning bg-warning'
            name = 'Admins'
            break
        case 1:
            className = 'text-info bg-info'
            name = 'Users'
            break
        default:
            name = [
                {
                    id: 0,
                    label: 'Admins',
                },
                {
                    id: 1,
                    label: 'Users',
                },
            ]
            break
    }
    if (check || !className) return name
    return <div className={`inline-flex rounded-full bg-opacity-10 py-1 px-3 text-sm font-medium ${className}`}>{name}</div>
}

export function registerType() {
    return [
        {
            id: 'collector',
            label: 'Collector',
        },
        {
            id: 'affiliate',
            label: 'Recycler',
        },
        {
            id: 'collection-point',
            label: 'Collection Point',
        },
    ]
}

export function projectType(type = '', check = false) {
    var className = ''
    var name = ''
    switch (type) {
        case 0:
            className = 'text-warning bg-warning'
            name = 'Residential'
            break
        case 1:
            className = 'text-info bg-info'
            name = 'Commercial'
            break
        default:
            name = [
                {
                    id: 0,
                    label: 'Residential',
                },
                {
                    id: 1,
                    label: 'Commercial',
                },
            ]
            break
    }
    if (check || !className) return name
    return <div className={`inline-flex rounded-full bg-opacity-10 py-1 px-3 text-sm font-medium ${className}`}>{name}</div>
}

export function bulkoBagStatus(status, check = false) {
    var className = ''
    var name = ''
    switch (status) {
        case 1:
            className = 'text-info bg-info'
            name = ' Awaiting Shipment'
            break
        case 2:
            className = 'text-warning bg-info'
            name = 'Collected'
            break
        case 3:
            className = 'text-warning bg-warning'
            name = 'Batch Initiated'
            break
        case 4:
            className = 'text-success bg-success'
            name = 'Batch Completed'
            break
        case 5:
            className = 'text-danger bg-danger'
            name = 'Inactive'
            break
        default:
            className = 'text-success bg-success'
            name = 'Active'
            break
    }
    if (check) return name
    return <div className={`inline-flex rounded-full bg-opacity-10 py-1 px-3 text-sm font-medium ${className}`}>{name}</div>
}

export function bulkoBagType(type = '', check = false) {
    var className = ''
    var name = ''
    switch (type) {
        case 0:
            className = 'text-warning bg-warning'
            name = 'Single Tag'
            break
        case 1:
            className = 'text-info bg-info'
            name = 'Multiple Tag'
            break
        default:
            name = [
                {
                    id: 0,
                    label: 'Single Tag',
                },
                {
                    id: 1,
                    label: 'Multiple Tag',
                },
            ]
            break
    }
    if (check || !className) return name
    return <div className={`inline-flex rounded-full bg-opacity-10 py-1 px-3 text-sm font-medium ${className}`}>{name}</div>
}

export function wasteDropsLocation(type = '', check = false) {
    var className = ''
    var name = ''
    switch (type) {
        case 0:
            className = 'text-warning bg-warning'
            name = 'Project Site'
            break
        case 1:
            className = 'text-info bg-info'
            name = 'Collection Point'
            break
        default:
            name = [
                {
                    id: 0,
                    label: 'Project Site',
                },
                {
                    id: 1,
                    label: 'Collection Point',
                },
            ]
            break
    }
    if (check || !className) return name
    return <div className={`inline-flex rounded-full bg-opacity-10 py-1 px-3 text-sm font-medium ${className}`}>{name}</div>
}

export function wasteDropsStatus(status, check = false) {
    var className = ''
    var name = ''
    switch (status) {
        case 1:
            className = 'text-info bg-info'
            name = 'Confirmed'
            break
        case 2:
            className = 'text-warning bg-warning'
            name = 'For Review'
            break
        case 3:
            className = 'text-danger bg-danger'
            name = 'Rejected'
            break
        case 4:
            className = 'text-success bg-success'
            name = 'Allocated'
            break
        default:
            className = 'text-warning bg-warning'
            name = 'Pending'
            break
    }
    if (check) return name
    return <div className={`inline-flex rounded-full bg-opacity-10 py-1 px-3 text-sm font-medium ${className}`}>{name}</div>
}

export function pickupStatus(status, check = false) {
    var className = ''
    var name = ''
    switch (status) {
        case 1:
            className = 'text-info bg-info'
            name = 'Accepted'
            break
        case 2:
            className = 'text-danger bg-danger'
            name = 'Cancel'
            break
        case 3:
            className = 'text-success bg-success'
            name = 'In transit'
            break
        case 4:
            className = 'text-info bg-info'
            name = 'Completed'
            break
        default:
            className = 'text-warning bg-warning'
            name = 'Pending'
            break
    }
    if (check) return name
    return <div className={`inline-flex rounded-full bg-opacity-10 py-1 px-3 text-sm font-medium ${className}`}>{name}</div>
}

export function batcheStatus(status, check = false) {
    var className = ''
    var name = ''
    switch (status) {
        case 1:
            className = 'text-success bg-success'
            name = 'Completed'
            break
        default:
            className = 'text-warning bg-warning'
            name = 'WIP'
            break
    }
    if (check) return name
    return <div className={`inline-flex rounded-full bg-opacity-10 py-1 px-3 text-sm font-medium ${className}`}>{name}</div>
}

export function bagRequestStatus(status, check = false) {
    var className = ''
    var name = ''
    switch (status) {
        case 1:
            className = 'text-success bg-info'
            name = 'Accepted'
            break
        case 2:
            className = 'text-danger bg-danger'
            name = 'Rejected'
            break
        case 3:
            className = 'text-success bg-success'
            name = 'Sent'
            break
        default:
            className = 'text-warning bg-warning'
            name = 'Pending'
            break
    }
    if (check) return name
    return <div className={`inline-flex rounded-full bg-opacity-10 py-1 px-3 text-sm font-medium ${className}`}>{name}</div>
}

export function yesNo(type = '', check = false) {
    var className = ''
    var name = ''
    switch (type) {
        case 0:
            className = 'text-danger bg-danger'
            name = 'No'
            break
        case 1:
            className = 'text-success bg-success'
            name = 'Yes'
            break
        default:
            name = [
                {
                    id: 0,
                    label: 'No',
                },
                {
                    id: 1,
                    label: 'Yes',
                },
            ]
            break
    }
    if (check || !className) return name
    return <div className={`inline-flex rounded-full bg-opacity-10 py-1 px-3 text-sm font-medium ${className}`}>{name}</div>
}

export function cageTypes(type = '', check = false) {
    var className = ''
    var name = ''
    switch (type) {
        case 0:
            className = 'text-warning bg-warning'
            name = 'Virtual'
            break
        case 1:
            className = 'text-info bg-info'
            name = 'Physical'
            break
        default:
            name = [
                {
                    id: 0,
                    label: 'Virtual',
                },
                {
                    id: 1,
                    label: 'Physical',
                },
            ]
            break
    }
    if (check || !className) return name
    return <div className={`inline-flex rounded-full bg-opacity-10 py-1 px-3 text-sm font-medium ${className}`}>{name}</div>
}

export function collectionPointStatus(type = '', check = false) {
    var className = ''
    var name = ''
    switch (type) {
        case 0:
            className = 'text-warning bg-warning'
            name = 'Pending'
            break
        case 1:
            className = 'text-success bg-success'
            name = 'Accepted'
            break
        case 2:
            className = 'text-danger bg-danger'
            name = 'Rejected'
            break
        default:
            name = [
                {
                    id: 0,
                    label: 'Pending',
                },
                {
                    id: 1,
                    label: 'Accepted',
                },
                {
                    id: 2,
                    label: 'Rejected',
                },
            ]
            break
    }
    if (check || !className) return name
    return <div className={`inline-flex rounded-full bg-opacity-10 py-1 px-3 text-sm font-medium ${className}`}>{name}</div>
}

export function sendMailType(type = '') {
    var name = ''
    switch (type) {
        case 0:
            name = 'All'
            break
        case 1:
            name = 'Collection Points'
            break
        case 2:
            name = 'Recyclers'
            break
        case 3:
            name = 'Collectors'
            break
        default:
            name = [
                {
                    id: 0,
                    label: 'All',
                },
                {
                    id: 1,
                    label: 'Collection Points',
                },
                {
                    id: 2,
                    label: 'Recyclers',
                },
                {
                    id: 3,
                    label: 'Collectors',
                },
            ]
            break
    }
    return name
}

export function fileUploadsType(type = '', check = false) {
    var className = ''
    var name = ''
    switch (type) {
        case 0:
            className = 'text-info bg-info'
            name = 'Collection Points'
            break
        case 1:
            className = 'text-success bg-success'
            name = 'Recyclers'
            break
        case 2:
            className = 'text-danger bg-danger'
            name = 'Collectors'
            break
        default:
            name = [
                {
                    id: 0,
                    label: 'Collection Points',
                },
                {
                    id: 1,
                    label: 'Recyclers',
                },
                {
                    id: 2,
                    label: 'Collectors',
                },
            ]
            break
    }
    if (check || !className) return name
    return <div className={`inline-flex rounded-full bg-opacity-10 py-1 px-3 text-sm font-medium ${className}`}>{name}</div>
}

export function contactType(type, check = false) {
    var className = ''
    var name = ''
    switch (type) {
        case 1:
            className = 'text-danger bg-danger'
            name = 'Collector'
            break
        default:
            className = 'text-success bg-success'
            name = 'User'
            break
    }
    if (check) return name
    return <div className={`inline-flex rounded-full bg-opacity-10 py-1 px-3 text-sm font-medium ${className}`}>{name}</div>
}

export function contactStatus(status, check = false) {
    var className = ''
    var name = ''
    switch (status) {
        case 1:
            className = 'text-warning bg-warning'
            name = 'Work In Progress'
            break
        case 2:
            className = 'text-success bg-success'
            name = 'Resolve'
            break
        case 3:
            className = 'text-danger bg-danger'
            name = 'Not Resolve'
            break
        case 4:
            className = 'text-warning bg-warning'
            name = 'On Hold'
            break
        default:
            className = 'text-info bg-info'
            name = 'Submit'
            break
    }
    if (check) return name
    return <div className={`inline-flex rounded-full bg-opacity-10 py-1 px-3 text-sm font-medium ${className}`}>{name}</div>
}

export function memberType(status = '', check = false) {
    var className = ''
    var name = ''
    switch (status) {
        case 0:
            className = 'text-danger bg-danger'
            name = 'Existing'
            break
        case 1:
            className = 'text-success bg-success'
            name = 'Future'
            break
        default:
            name = [
                {
                    id: 0,
                    label: 'Existing',
                },
                {
                    id: 1,
                    label: 'Future',
                },
            ]
            break
    }
    if (check || !className) return name
    return <div className={`inline-flex rounded-full bg-opacity-10 py-1 px-3 text-sm font-medium ${className}`}>{name}</div>
}
