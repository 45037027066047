import { clone, remove } from 'lodash'
import { AiOutlineClose, AiOutlinePlus } from 'react-icons/ai'

import { Button, FileUpload, Select, TextInput } from 'Components/Form'
import BulkoBagMaterial from './BulkoBagMaterial'

export const dataDefault = {
    bulko_bag_id: '',
    image: '',
    images: '',
    notes: '',
    batch_bulko_bag_material: [],
}

export default function BulkoBag({ index, datas, data, setFieldValue, touched, errors, bulko_bags, batchRejectTypes }) {
    var name = `batch_bulko_bag[${index}].`

    return (
        <div className="grid grid-cols-1 gap-3 pt-3 border-t md:grid-cols-11 border-stroke">
            <div className="flex flex-col col-span-10 gap-3">
                <div className="grid grid-cols-2 gap-3 md:grid-cols-6">
                    <Select
                        id={`${name}bulko_bag_id`}
                        name={`${name}bulko_bag_id`}
                        label="List of Bins"
                        placeholder="List of Bins"
                        value={data.bulko_bag_id}
                        options={bulko_bags.map(e => ({
                            id: e.id,
                            label: e.number,
                        }))}
                        onChange={e => {
                            setFieldValue(`${name}bulko_bag_id`, e.target.value)
                            var bulko_bag = bulko_bags.find(f => f.id === e.target.value)
                            setFieldValue(
                                `${name}batch_bulko_bag_material`,
                                bulko_bag?.waste_drop_bulko_bag.map(f => ({
                                    waste_drop_material_type_id: f.waste_drop_material_type_id,
                                    waste_drop_material_type: f.waste_drop_material_type,
                                    material_id: f.waste_drop_material_type.material_id,
                                    material: f.waste_drop_material_type.material,
                                    accept_bags: f.waste_drop_material_type.accept_bags,
                                    accept_weight: f.waste_drop_material_type.accept_weight,
                                    reject_bags: 0,
                                    reject_weight: 0,
                                    batch_reject_type_id: '',
                                    reject_type: '',
                                })) ?? []
                            )
                        }}
                        error={touched?.bulko_bag_id && errors?.bulko_bag_id}
                        className="col-span-2 sm:col-span-2"
                    />
                    <div className="col-span-3 sm:col-span-2">
                        <FileUpload
                            id={`${name}images`}
                            name={`${name}images`}
                            label="Images"
                            placeholder="Images"
                            error={touched?.images && errors?.images}
                            onChange={e => setFieldValue(`${name}images`, e.currentTarget.files[0])}
                        />
                        {data.image_url ? (
                            <a
                                href={data.image_url + '?download=' + data.image}
                                target="_blank"
                                rel="noreferrer"
                                className="cursor-pointer"
                            >
                                <p className="text-sm text-black dark:text-white hover:text-primary dark:hover:text-secondary">
                                    Download
                                </p>
                            </a>
                        ) : null}
                    </div>
                    <TextInput
                        id={`${name}notes`}
                        name={`${name}notes`}
                        label="Notes"
                        placeholder="Notes"
                        value={data.notes}
                        error={touched?.notes && errors?.notes}
                        className="col-span-3 sm:col-span-2"
                    />
                </div>
                <div className="grid grid-cols-1 gap-3">
                    {data.batch_bulko_bag_material.map((data, index) => (
                        <BulkoBagMaterial
                            key={index}
                            names={name}
                            index={index}
                            datas={data.batch_bulko_bag_material}
                            data={data}
                            setFieldValue={setFieldValue}
                            touched={touched?.batch_bulko_bag_material?.[index] ?? null}
                            errors={errors?.batch_bulko_bag_material?.[index] ?? null}
                            bulko_bags={bulko_bags}
                            batchRejectTypes={batchRejectTypes}
                        />
                    ))}
                </div>
            </div>
            <div className="flex flex-row gap-2 md:pt-7 md:flex-col">
                {index + 1 === datas.length ? (
                    <Button
                        type="button"
                        className="text-white border-primary bg-primary dark:border-secondary dark:bg-secondary"
                        onClick={() => {
                            var saveTask = clone(datas)
                            saveTask.push(dataDefault)
                            setFieldValue('batch_bulko_bag', saveTask)
                        }}
                    >
                        <AiOutlinePlus size={20} />
                    </Button>
                ) : (
                    <Button
                        type="button"
                        className="text-white border-danger bg-danger"
                        onClick={() => {
                            var saveTask = clone(datas)
                            remove(saveTask, (n, key) => key === index)
                            setFieldValue('batch_bulko_bag', saveTask)
                        }}
                    >
                        <AiOutlineClose size={20} />
                    </Button>
                )}
            </div>
        </div>
    )
}
